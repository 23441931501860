export const PremiumStar = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.9512 9.67138C21.8335 9.30944 21.5206 9.04562 21.144 8.99072L15.4453 8.16259L12.897 2.99902C12.7198 2.67154 12.3721 2.47305 12 2.48702V18.7661L17.0972 21.4458C17.5861 21.7028 18.1909 21.5147 18.4479 21.0258C18.5501 20.8312 18.5855 20.6083 18.5483 20.3916L17.5747 14.7158L21.6982 10.6968C21.9708 10.431 22.0688 10.0335 21.9512 9.67138Z"
        fill="#A2A1FF"
      />
      <path
        d="M11.1032 2.99854L8.55483 8.16217L2.8561 8.99036C2.63863 9.02204 2.43764 9.12445 2.2842 9.28174C1.89846 9.67725 1.90639 10.3106 2.3019 10.6963L6.42544 14.7153L5.45181 20.3911C5.4147 20.6078 5.45004 20.8307 5.55227 21.0253C5.80929 21.5143 6.41403 21.7023 6.90298 21.4453L12.0001 18.7656V2.50391C11.6445 2.5047 11.2971 2.67017 11.1032 2.99854Z"
        fill="#6563FF"
      />
    </svg>
  )
}
