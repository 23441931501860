import { tg } from '../../App'
import { Button } from '../../ui/Button/Button'
import { Card } from '../../ui/Card/Card'

export const TermsOfUsePage = () => {
  return (
    <Card title="Пользовательское соглашение">
      <Button
        fullWidth
        onClick={() => {
          tg.openLink('https://relevants.space/tos.docx')
        }}
      >
        Скачать документ
      </Button>
    </Card>
  )
}
