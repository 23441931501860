import { useEffect, useState } from 'react'
import { getUsers } from '../../clients/AdminClient'
import { Input } from '@mui/joy'
import { useNavigate } from 'react-router-dom'
import { UserExplicit, UserId } from '../../models/User'
import { birthYearToAge } from '../../tools/StringUtil'
import styles from './AdminUsersPage.module.sass'

export const AdminUsersPage = () => {
  const [users, setUsers] = useState<UserExplicit[]>()
  const [query, setQuery] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    getUsers().then(({ data }) => setUsers(data))
  }, [])

  return (
    <div className={styles.profilesWrapper}>
      {users && (
        <>
          <span>Всего: {users.length}</span>
          <div>
            <Input placeholder="Поиск" value={query} onChange={e => setQuery(e.target.value)} />
          </div>
          {users
            ?.filter(u =>
              !query
                ? true
                : u.telegramId === Number(query) ||
                  u.id === (Number(query) as UserId) ||
                  u.personName?.toLowerCase().includes(query.toLowerCase()),
            )
            .map(u => (
              <div className={styles.profileTile} key={u.id} onClick={() => navigate(`/admin/user/${u.id}`)}>
                <div>
                  <b>(ID: {u.id})</b>{' '}
                  <span>
                    {u.personName || 'No Name'}, {(u.birthYear && birthYearToAge(u.birthYear)) + ' лет' || 'No Age'}
                  </span>
                </div>
                <div>Telegram ID: {u.telegramId}</div>
                <div>Профили: {u.profilesCount}</div>
                <div>{u.createdAt}</div>
              </div>
            ))}
        </>
      )}
    </div>
  )
}
