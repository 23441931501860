import { useEffect, useState } from 'react'
import { ProfileCompact, ProfileVariant } from '../../models/Profile'
import { getProfiles } from '../../clients/AdminClient'
import { Input } from '@mui/joy'
import { useNavigate } from 'react-router-dom'
import styles from './AdminProfilesPage.module.sass'

export const ProfileCompactAdminListItem = ({ profile }: { profile: ProfileCompact }) => {
  const navigate = useNavigate()

  return (
    <div className={styles.profileTile} key={profile.id} onClick={() => navigate(`/admin/profile/${profile.id}`)}>
      <div>
        <b>{profile.position}</b>
      </div>
      <div>{profile.variant}</div>
      <div>{profile.createdAt}</div>
    </div>
  )
}

export const AdminProfilesPage = () => {
  const [profiles, setProfiles] = useState<ProfileCompact[]>()
  const [query, setQuery] = useState('')

  useEffect(() => {
    getProfiles().then(({ data }) => setProfiles(data))
  }, [])

  const lastDayProfiles = profiles?.filter(
    p => new Date(p.createdAt) > new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
  )

  return (
    <div className={styles.profilesWrapper}>
      {profiles && lastDayProfiles && (
        <>
          <span>
            День: {lastDayProfiles.length} ({lastDayProfiles.filter(p => p.variant === ProfileVariant.Job).length} J /{' '}
            {lastDayProfiles.filter(p => p.variant === ProfileVariant.Resume).length} R)
          </span>
          <span>
            Всего: {profiles.length} ({profiles.filter(p => p.variant === ProfileVariant.Job).length} J /{' '}
            {profiles.filter(p => p.variant === ProfileVariant.Resume).length} R)
          </span>
          <div>
            <Input placeholder="Поиск" value={query} onChange={e => setQuery(e.target.value)} />
          </div>
          {profiles
            ?.filter(p => p.position.toLowerCase().includes(query.toLowerCase()))
            .map(p => (
              <ProfileCompactAdminListItem key={p.id} profile={p} />
            ))}
        </>
      )}
    </div>
  )
}
