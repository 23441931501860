import config from '../../config'
import { LikeMiniIcon } from '../../icons/LikeMiniIcon'
import styles from './Avatar.module.sass'

interface AvatarProps {
  profilePicture?: string
  progress?: number
  size?: string | number
  hasLike?: boolean
}
export const Avatar = ({ profilePicture, progress, size = 74, hasLike = false }: AvatarProps) => {
  return (
    <div
      className={styles.avatarWrapper}
      style={{ backgroundImage: `url(${config.API_URL}/${profilePicture})`, width: size, height: size }}
    >
      {hasLike && (
        <div className={styles.like}>
          <LikeMiniIcon />
        </div>
      )}
    </div>
  )
}
