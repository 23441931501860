import { Checkbox, Chip, CircularProgress, FormControl, FormHelperText, FormLabel, Radio, Slider } from '@mui/joy'
import {
  ProfileFinancialPeriod,
  ProfilePreviewDetails,
  ProfileVariant,
  ProfileWorkType,
  profileFinancialPeriodLabels,
} from '../../models/Profile'
import { StepperStep } from '../../ui/Stepper/StepperStep'
import { StepperBottomMenu } from '../../ui/Stepper/StepperBottomMenu'
import { Button } from '../../ui/Button/Button'
import { BackButton } from '../../ui/Button/BackButton'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSafeContext } from '../../ui/hooks/useSafeContext'
import { InputFormField } from '../../ui/Input'
import { createProfile } from '../../clients/ProfileClient'
import { TextareaFormField } from '../../ui/Textarea'
import { ImageUploader } from '../../ui/ImageCrop/ImageCrop'
import { ProfileDetails } from '../../ui/ProfileDetails/ProfileDetails'
import { useAppMounting, useAppState } from '../../ui/AppState'
import { useNavigate } from 'react-router-dom'
import { useToast } from '../../ui/Toast/Toast'
import { HttpStatusCode } from 'axios'
import { CreateProfileContext } from './CreateProfilePage'
import { DocumentIcon } from '../../icons/DocumentIcon'
import { MainButtonContext, useDisabledMainButton } from '../../ui/MainButtonContext'
import { isIOS } from '../../tools/ClientUtil'
import citiesList from '../../cities.json'
import { plural } from '../../tools/StringUtil'
import { PremiumStar } from '../../icons/PremiumStar'
import styles from './CreateProfilePage.module.sass'

type StepVariant = 'job' | 'resume'

type GenericComponent = (varian: StepVariant) => { Component: () => JSX.Element }

const LocationStep = () => {
  const { onClose, state, onChange, errors: validationErrors, next } = useSafeContext(CreateProfileContext)

  const allCities = useMemo(() => citiesList.map(({ city }) => city), [])

  const [cities, setCities] = useState<string[]>(['Москва', 'Санкт-Петербург'])
  const [shouldHideHints, setShouldHideHints] = useState(false)

  const errors = [...(validationErrors?.location ? ['Максимальная длина - 64 символов'] : [])]

  useEffect(() => {
    setCities(
      state.location
        ? allCities.filter(c => c.toLowerCase().startsWith(state.location?.toLowerCase() ?? '')).slice(0, 10)
        : ['Москва', 'Санкт-Петербург'],
    )
  }, [state.location, setShouldHideHints, allCities])

  const isDisabled = !!errors.length

  useDisabledMainButton(isDisabled)

  return (
    <StepperStep title="Где вы проживаете?">
      <div className={styles.locationControlsWrapper}>
        <InputFormField
          initiallyTouched
          label="Город"
          helperText="Данная информация поможет нам в создании наиболее релевантных результатов"
          placeholder="Например: Москва"
          value={state.location ?? ''}
          onChange={e => onChange('location')(e.target.value)}
          onKeyDown={() => setShouldHideHints(false)}
          errors={errors}
          error={isDisabled}
          bottomContent={
            <>
              {!shouldHideHints &&
                (cities.length !== 1 || cities[0].toLowerCase() !== state.location?.toLowerCase()) && (
                  <div className={styles.citiesHints}>
                    {cities.map((c, i) => (
                      <Chip
                        variant="soft"
                        color="primary"
                        size="md"
                        key={c + i}
                        onMouseDown={() => {
                          onChange('location')(c)
                          setShouldHideHints(true)
                        }}
                      >
                        {c}
                      </Chip>
                    ))}
                  </div>
                )}
            </>
          }
        />
      </div>
      <StepperBottomMenu>
        <BackButton onClick={onClose} />
        <Button fullWidth disabled={isDisabled} onClick={next}>
          Продолжить
        </Button>
      </StepperBottomMenu>
    </StepperStep>
  )
}

const PositionStep: GenericComponent = variant => ({
  Component: () => {
    const { state, onChange, errors, next, back } = useSafeContext(CreateProfileContext)
    const isDisabled = !!errors?.position
    useDisabledMainButton(isDisabled)

    return (
      <StepperStep title={variant === 'resume' ? `Ваша должность` : `Кого вы ищете?`}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <InputFormField
            initiallyTouched={!!(errors?.position && state.position)}
            label="Должность*"
            helperText="Данная информация поможет нам в создании наиболее релевантных результатов"
            placeholder="Например: Эксперт в области фитнеса"
            value={state.position ?? ''}
            onChange={e => onChange('position')(e.target.value)}
            errors={errors?.position ? ['Длина поля должна быть от 2 до 64 символов'] : undefined}
            error={isDisabled}
          />
          <FormControl>
            <FormLabel>Формат работы</FormLabel>
            <div className={styles.workTypeStepWrapper}>
              <Checkbox
                label="Full-time"
                checked={state.workType?.includes(ProfileWorkType.FullTime)}
                variant="outlined"
                color="primary"
                onChange={e => {
                  if (e.target.checked) {
                    onChange('workType')([...(state.workType ?? []), ProfileWorkType.FullTime])
                  } else {
                    onChange('workType')(state.workType?.filter(w => w !== ProfileWorkType.FullTime))
                  }
                }}
              />
              <Checkbox
                label="Part-time"
                checked={state.workType?.includes(ProfileWorkType.PartTime)}
                variant="outlined"
                color="primary"
                onChange={e => {
                  if (e.target.checked) {
                    onChange('workType')([...(state.workType ?? []), ProfileWorkType.PartTime])
                  } else {
                    onChange('workType')(state.workType?.filter(w => w !== ProfileWorkType.PartTime))
                  }
                }}
              />
            </div>
            <FormHelperText>Определите какой тип работы вам подходит</FormHelperText>
          </FormControl>
        </div>
        <StepperBottomMenu>
          <BackButton onClick={back} />
          <Button fullWidth disabled={isDisabled} onClick={next}>
            Продолжить
          </Button>
        </StepperBottomMenu>
      </StepperStep>
    )
  },
})

const LookingForDescriptionStep: GenericComponent = variant => ({
  Component: () => {
    const { state, onChange, errors, next, back } = useSafeContext(CreateProfileContext)

    const isDisabled = !!errors?.lookingForDescription
    useDisabledMainButton(isDisabled)

    useEffect(() => {
      if (state.experience === undefined) {
        onChange('experience')(0)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange])

    return (
      <StepperStep title={variant === 'resume' ? 'Что вы ищете?' : 'Кого вы ищете?'}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextareaFormField
            label="Требования *"
            initiallyTouched={!!(errors?.lookingForDescription && state.lookingForDescription)}
            helperText={
              variant === 'resume'
                ? 'Здесь вы можете указать, какие проекты или работа вас заинтересует, ваши требования к компании'
                : 'Здесь вы можете указать необходимые навыки, технологии и любые другие требования к кандидату'
            }
            placeholder={
              variant === 'resume'
                ? 'Например: Хочу работать в опытном коллективе, в котором я смогу опробовать новые инструменты'
                : 'Например: Требуется специалист с опытом в таких программах как Adobe Photoshop, Adobe Illustrator'
            }
            value={state.lookingForDescription}
            onChange={e => onChange('lookingForDescription')(e.target.value)}
            errors={errors?.lookingForDescription ? ['Длина поля должна быть от 10 до 700 символов'] : undefined}
            error={isDisabled}
          />
          {variant === 'job' && (
            <FormControl>
              <FormLabel>Минимальный опыт *</FormLabel>
              <Slider
                valueLabelDisplay="auto"
                marks
                min={0}
                max={10}
                onChange={(_, experience) => onChange('experience')(experience as number)}
                value={state.experience}
              />
              <FormHelperText>
                {state.experience === 10
                  ? 'Ищем кандидатов с 10+ годами опыта'
                  : !state.experience
                  ? 'Не учитываем опыт'
                  : `Ищем кандидатов с опытом от ${state.experience} ${plural(state.experience, [
                      'года',
                      'лет',
                      'лет',
                    ])}`}
              </FormHelperText>
            </FormControl>
          )}
        </div>
        <StepperBottomMenu>
          <BackButton onClick={back} />
          <Button fullWidth disabled={isDisabled} onClick={next}>
            Продолжить
          </Button>
        </StepperBottomMenu>
      </StepperStep>
    )
  },
})

const DescriptionStep: GenericComponent = variant => ({
  Component: () => {
    const { state, onChange, errors, next, back } = useSafeContext(CreateProfileContext)

    const isDisabled = !!errors?.description
    useDisabledMainButton(isDisabled)
    return (
      <>
        <StepperStep title="Расскажите о себе">
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <TextareaFormField
              variant="soft"
              initiallyTouched={!!(errors?.description && state.description)}
              label="Информация о себе *"
              helperText={
                variant === 'resume'
                  ? 'Здесь вы можете указать информацию о себе, своих навыках и прошлом опыте работы'
                  : 'Здесь вы можете рассказать о проекте, вакансии или компании'
              }
              placeholder={
                variant === 'resume'
                  ? 'Например: знание фреймворков React.JS и Angular, отличный командный игрок'
                  : 'Нажмите, чтобы добавить'
              }
              value={state.description}
              onChange={e => onChange('description')(e.target.value)}
              errors={errors?.description ? ['Длина поля должна быть от 10 до 700 символов'] : undefined}
              error={isDisabled}
            />
            {variant === 'resume' && (
              <FormControl>
                <FormLabel>Ваш опыт по специальности *</FormLabel>
                <Slider
                  valueLabelDisplay="auto"
                  marks
                  min={0}
                  max={10}
                  onChange={(_, experience) => onChange('experience')(experience as number)}
                  value={state.experience}
                />
                <FormHelperText>
                  {state.experience === 10
                    ? '10 или более лет опыта'
                    : !state.experience
                    ? 'Без опыта'
                    : `Ваш опыт по специальности: ${state.experience} ${plural(state.experience, [
                        'год',
                        'года',
                        'лет',
                      ])}`}
                </FormHelperText>
              </FormControl>
            )}
          </div>
          <StepperBottomMenu>
            <BackButton onClick={back} />
            <Button fullWidth disabled={isDisabled} onClick={next}>
              Продолжить
            </Button>
          </StepperBottomMenu>
        </StepperStep>
      </>
    )
  },
})

const FinancialStep = () => {
  const { state, onChange, errors, next, back } = useSafeContext(CreateProfileContext)

  const isPriceRangeInvalid = (state.financialMin ?? 0) > (state.financialMax ?? state.financialMin ?? 0)

  const isDisabled = !!errors?.financialMin || !!errors?.financialMax || isPriceRangeInvalid || !!errors?.extraInfo
  useDisabledMainButton(isDisabled)

  return (
    <StepperStep title="Выберите вариант оплаты труда">
      <div className={styles.workTypeStepWrapper}>
        <div className={styles.workTypeWrapper}>
          {[ProfileFinancialPeriod.Hour, ProfileFinancialPeriod.Month, ProfileFinancialPeriod.Project].map(p => (
            <Radio
              key={p}
              variant="outlined"
              color="primary"
              label={profileFinancialPeriodLabels[p]}
              onClick={() => onChange('financialPeriod')(state.financialPeriod !== p ? p : undefined)}
              checked={state.financialPeriod === p}
            />
          ))}
        </div>
        <InputFormField
          initiallyTouched={
            !!(state.financialMin && (state.financialMin ?? 0) > (state.financialMax ?? state.financialMin ?? 0))
          }
          label="Минимальная сумма (RUB)"
          placeholder="Например: 20000"
          value={state.financialMin ?? ''}
          onChange={e => {
            const amount = parseInt(e.target.value, 10)
            onChange('financialMin')(isNaN(amount) ? undefined : amount)
          }}
          errors={
            errors?.financialMin || !isPriceRangeInvalid ? ['От 0 рублей, но не более максимальной суммы'] : undefined
          }
          error={(state.financialMin ?? 0) > (state.financialMax ?? state.financialMin ?? 0)}
          type="number"
        />
        <InputFormField
          id="dummyId"
          initiallyTouched={
            !!(state?.financialMax && (state.financialMin ?? 0) > (state.financialMax ?? state.financialMin ?? 0))
          }
          label="Максимальная сумма (RUB)"
          placeholder="Например: 150000"
          value={state.financialMax ?? ''}
          onChange={e => {
            const amount = parseInt(e.target.value, 10)
            onChange('financialMax')(isNaN(amount) ? undefined : amount)
          }}
          errors={
            errors?.financialMax || isPriceRangeInvalid ? ['От 0 рублей, но не более максимальной суммы'] : undefined
          }
          error={(state.financialMin ?? 0) > (state.financialMax ?? state.financialMin ?? 0)}
          type="number"
        />
        <TextareaFormField
          initiallyTouched={!!(state.extraInfo && errors?.extraInfo)}
          label="Дополнительно"
          helperText="Здесь вы можете указать любую дополнительную информацию, в том числе об условиях сотрудничества"
          placeholder="Например: работаю по предоплате 50%"
          value={state.extraInfo}
          onChange={e => onChange('extraInfo')(e.target.value)}
          errors={errors?.extraInfo ? ['Длина поля должна быть от 5 до 700 символов'] : undefined}
          error={!!errors?.extraInfo}
        />
      </div>
      <StepperBottomMenu>
        <BackButton onClick={back} />
        <Button fullWidth disabled={isDisabled} onClick={next}>
          Продолжить
        </Button>
      </StepperBottomMenu>
    </StepperStep>
  )
}

const ProfilePictureStep = () => {
  const { state, onChange, next, back } = useSafeContext(CreateProfileContext)

  return (
    <StepperStep title="Ваша фотография">
      <ImageUploader filePath={state.profilePicture ?? ''} setFilePath={onChange('profilePicture')} />
      <StepperBottomMenu>
        <BackButton onClick={back} />
        <Button fullWidth onClick={next}>
          Продолжить
        </Button>
      </StepperBottomMenu>
    </StepperStep>
  )
}

const PreviewStep = () => {
  const { state, back, resetProfile, onChange } = useSafeContext(CreateProfileContext)
  const { user, trackPremiumStats } = useAppState()
  const {
    hideProgress,
    showProgress,
    mainButtonState: { isProgressVisible },
  } = useSafeContext(MainButtonContext)

  const reloadApp = useAppMounting()

  const { showToast } = useToast()

  const navigate = useNavigate()

  const [isProcessingState, setProcessingState] = useState(false)
  const [isPreloaderOpenState, setPreloaderOpenState] = useState(false)

  const isProcessing = isIOS() ? isProgressVisible : isProcessingState
  const isPreloaderOpen = isIOS() ? isProgressVisible : isPreloaderOpenState

  const showProgressCb = useCallback(
    (val: boolean) => (val ? showProgress(false) : hideProgress()),
    [showProgress, hideProgress],
  )

  const setProcessing = isIOS() ? showProgressCb : setProcessingState
  const setPreloaderOpen = isIOS() ? showProgressCb : setPreloaderOpenState

  useEffect(() => {
    if (isIOS()) {
      return
    }

    const update = setTimeout(() => {
      setPreloaderOpen(isProcessing)
    }, 100)

    return () => {
      clearTimeout(update)
    }
  }, [isProcessing, setPreloaderOpen])

  return (
    <StepperStep className={styles.previewStepperStep}>
      {isPreloaderOpen && (
        <div className={styles.preloaderBackdrop}>
          <div className={styles.preloaderInner}>
            <CircularProgress />
            <span className={styles.preloaderLabel}>Искусственный интеллект обрабатывает вашу анкету</span>
          </div>
        </div>
      )}
      <ProfileDetails
        editable
        profile={
          {
            ...state,
            personName: user.personName,
            birthYear: user.birthYear,
            hasLike: false,
            id: 0,
            variant: state.variant!,
          } as ProfilePreviewDetails
        }
        onChange={onChange}
      />
      <Button
        startDecorator={<PremiumStar />}
        variant="soft"
        color={'info' as any}
        size="lg"
        sx={{ fontSize: '14px', width: '100%', marginTop: '16px' }}
        onClick={() => trackPremiumStats('ImproveProfile')}
      >
        Улучшить с помощью ИИ
      </Button>
      <StepperBottomMenu className={styles.createProfilePageMenu}>
        <Button
          startDecorator={<PremiumStar />}
          variant="soft"
          color={'info' as any}
          size="lg"
          sx={{ fontSize: '14px' }}
          onClick={() => trackPremiumStats('ImproveProfile')}
        >
          Улучшить с помощью ИИ
        </Button>
        <div className={styles.pageMenuButtons}>
          <BackButton onClick={back} disabled={isProcessing} />
          <Button
            fullWidth
            onClick={() => {
              setProcessing(true)

              createProfile({ ...state, category: '1938810a-5422-4058-b12d-fd648812eba9' })
                .then(({ data }) => {
                  resetProfile()
                  navigate(`/profile/${data.id}`)
                  if (user.everCreatedProfilesCount === 0) {
                    reloadApp()
                  }
                })
                .catch(({ response }) => {
                  if (response.status === HttpStatusCode.BadRequest) {
                    showToast({
                      title: 'Ошибка',
                      description: 'Некоторые поля заполнены некорректно',
                      variant: 'error',
                    })
                  } else {
                    showToast({
                      title: 'Ошибка',
                      description: response?.data?.details?.ru ?? 'Что-то пошло не так',
                      variant: 'error',
                    })
                  }
                })
                .finally(() => {
                  setProcessing(false)
                  setPreloaderOpen(false)
                })
            }}
            className={styles.createProfileButton}
            disabled={!!(isProcessing || !state.tags || !new Set(...state.tags).size)}
            loading={isProcessing}
          >
            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
              <DocumentIcon style={{ width: 24, height: 24 }} />
              Опубликовать {state.variant === ProfileVariant.Resume ? 'резюме' : 'вакансию'}
            </div>
          </Button>
        </div>
      </StepperBottomMenu>
    </StepperStep>
  )
}

export const createProfileSteps = (variant: 'job' | 'resume'): (() => JSX.Element)[] => [
  LocationStep,
  PositionStep(variant).Component,
  // CategoryStep,
  ...[variant === 'job' ? LookingForDescriptionStep(variant).Component : DescriptionStep(variant).Component],
  ...[variant === 'job' ? DescriptionStep(variant).Component : LookingForDescriptionStep(variant).Component],
  FinancialStep,
  ProfilePictureStep,
  PreviewStep,
]

export const jobStepsNames = ['Локация', 'Название позиции', 'Кого ищу', 'О вакансии', 'Финансы', 'Фото', 'Превью']
export const resumeStepsNames = ['Локация', 'Название позиции', 'О себе', 'Что ищу', 'Финансы', 'Фото', 'Превью']
