import React, { useEffect } from 'react'
import styles from './PageLayout.module.sass'

interface PageLayoutProps {
  title?: string
  children: React.ReactNode
  className?: string

  bottomMenu?: boolean
  topMenu?: boolean
}
export const PageLayout = React.forwardRef<HTMLDivElement, PageLayoutProps>(
  ({ children, className, bottomMenu, topMenu, title }: PageLayoutProps, ref) => {
    useEffect(() => {
      if (title) {
        document.title = title
      }
    }, [title])

    return (
      <div
        ref={ref}
        className={
          styles.pageWrapper +
          (className ? ` ${className}` : ``) +
          (bottomMenu ? ` ${styles.bottomMenu}` : ``) +
          (topMenu ? ` ${styles.topMenu}` : ``)
        }
      >
        {children}
      </div>
    )
  },
)
