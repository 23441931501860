import { Card } from '../../ui/Card/Card'
import { Button } from '../../ui/Button/Button'
import { tg } from '../../App'

export const ContactSupportPage = () => {
  return (
    <Card title="Обратная связь">
      <Button
        fullWidth
        onClick={() => {
          tg.openLink('mailto:relevants@changency.dev')
        }}
      >
        Написать на почту
      </Button>
    </Card>
  )
}
