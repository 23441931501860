import { useSafeContext } from '../../ui/hooks/useSafeContext'
import { StepperStep } from '../../ui/Stepper/StepperStep'
import { InputFormField } from '../../ui/Input'
import { Button } from '../../ui/Button/Button'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { StepperBottomMenu } from '../../ui/Stepper/StepperBottomMenu'
import { birthYearToAge } from '../../tools/StringUtil'
import { RegisterPageContext } from './RegisterPage'
import { NavLink } from 'react-router-dom'
import styles from './RegisterPage.module.sass'
import { acceptTOS } from '../../clients/UserClient'
import { useAppState } from '../../ui/AppState'
import { useDisabledMainButton } from '../../ui/MainButtonContext'

const TOSStep = () => {
  const { user } = useAppState()
  const context = useSafeContext(RegisterPageContext)

  return (
    <StepperStep title="Условия использования сервиса">
      <p className={styles.tosLabel}>
        Нажимая на кнопку «Продолжить» вы соглашаетесь с{' '}
        <NavLink to="/settings/terms">условиями использования сервиса</NavLink> и{' '}
        <NavLink to="/settings/pd">политикой обработки персональных данных</NavLink>.
      </p>
      <StepperBottomMenu>
        <Button
          fullWidth
          onClick={() => {
            if (!user.hasAcceptedTOS) {
              acceptTOS().then(() => {
                context.next()
              })
            } else {
              context.next()
            }
          }}
        >
          Продолжить
        </Button>
      </StepperBottomMenu>
    </StepperStep>
  )
}

const NameStep = () => {
  const context = useSafeContext(RegisterPageContext)

  const isValid = !context.errors.personName

  useDisabledMainButton(!isValid)

  return (
    <StepperStep title="Напишите ваше полное имя">
      <InputFormField
        label="Имя*"
        value={context.state.personName ?? ''}
        onChange={e => context.onChange('personName')(e.target.value)}
        required
        helperText={
          <div>
            <div>
              Так ваше имя будет выглядеть в профиле и его будет видеть пользователь в выдаче предложенных вариантов.
            </div>
            <b>Имя нельзя будет изменить.</b>
          </div>
        }
        placeholder="Например: Геннадий"
        error={!isValid}
        errors={context.errors.personName ? ['Введите имя от 2 до 12 символов'] : undefined}
      />
      <StepperBottomMenu>
        <Button disabled={!isValid} fullWidth onClick={context.next}>
          Продолжить
        </Button>
      </StepperBottomMenu>
    </StepperStep>
  )
}

const AgeStep = () => {
  const { state, onChange, errors, back, onRegister } = useSafeContext(RegisterPageContext)

  const isValid = !errors.birthYear

  useDisabledMainButton(!isValid)

  return (
    <StepperStep title="Напишите ваш возраст">
      <InputFormField
        label="Возраст*"
        value={birthYearToAge(state.birthYear) || ''}
        onChange={e => onChange('birthYear')(birthYearToAge(+e.target.value))}
        helperText={
          <div>
            <div>Ваш возраст будет меняться автоматически в начале каждого года.</div>
            <b>Возраст нельзя будет изменить.</b>
          </div>
        }
        placeholder="Например: 23"
        type="number"
        errors={errors.birthYear ? ['Возраст должен быть от 18 лет'] : undefined}
      />
      <StepperBottomMenu>
        <Button onClick={back} variant="outlined" className={styles.backButton}>
          <ChevronLeftIcon />
        </Button>
        <Button disabled={!isValid} fullWidth onClick={onRegister}>
          Регистрация
        </Button>
      </StepperBottomMenu>
    </StepperStep>
  )
}

export const createUserSteps: (() => JSX.Element)[] = [TOSStep, NameStep, AgeStep]
