import { useClick, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react'
import styles from './ProfilesMenu.module.sass'
import { BottomNavigationItem } from '../BottomNavigation/BottomNavigationItem'
import { Avatar } from '../Avatar/Avatar'
import { ProfileCompact } from '../../models/Profile'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Badge, LinearProgress } from '@mui/joy'
import { SettingsIcon } from '../../icons/SettingsIcon'
import { PlusIcon } from '../../icons/PlusIcon'

const ProfileMenuItem = ({ profile, isCurrent }: { profile: ProfileCompact; isCurrent: boolean }) => {
  const navigate = useNavigate()
  return (
    <div
      className={styles.menuItemWrapper + (isCurrent ? ' ' + styles.active : '')}
      onClick={() => {
        navigate(`/profile/${profile.id}`)
        // window.location.href = `/profile/${profile.id}`
      }}
    >
      <div className={styles.iconWrapper}>
        <Badge badgeContent={profile.isArchived ? 0 : profile.incomingLikesCount} size="sm" variant="soft">
          <Avatar profilePicture={profile.profilePicture} size={32} />
        </Badge>
      </div>
      <div className={styles.label}>
        <span>{profile.position}</span>
      </div>
    </div>
  )
}

const CreateProfileMenuItem = () => {
  const navigate = useNavigate()
  return (
    <div className={styles.menuItemWrapper} onClick={() => navigate('/create-profile')}>
      <div className={styles.iconWrapper}>
        <PlusIcon style={{ width: 32, height: 32, fill: '#5a5a72' }} />
      </div>
      <div className={styles.label}>
        <span className={styles.secondary}>Создать профиль</span>
      </div>
    </div>
  )
}

const MainMenuProfileItem = () => {
  const navigate = useNavigate()
  return (
    <div className={styles.menuItemWrapper} onClick={() => navigate('/')}>
      <div className={styles.iconWrapper}>
        <SettingsIcon style={{ width: 26, height: 26, fill: '#5a5a72' }} />
      </div>
      <div className={styles.label}>
        <span className={styles.secondary}>Профили и управление</span>
      </div>
    </div>
  )
}

interface ProfilesMenuProps {
  className?: string
  profiles?: ProfileCompact[]
}
export const ProfilesMenu = ({ className, profiles }: ProfilesMenuProps) => {
  const [isOpen, setOpen] = useState(false)

  const { id } = useParams()

  const profile = profiles?.find(p => p.id === +(id ?? 0))

  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setOpen,
    placement: 'top-end',
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
  ])

  if (profiles && !profile) {
    return null
  }

  return (
    <>
      <BottomNavigationItem>
        <div
          style={{ height: '100%', display: 'flex', alignItems: 'center' }}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          <div className={className}>
            <Avatar profilePicture={profile?.profilePicture} size={24} />
            <span>Меню</span>
          </div>
        </div>
      </BottomNavigationItem>
      {isOpen && (
        <div style={{ ...floatingStyles }} className={styles.menu} ref={refs.setFloating} {...getFloatingProps()}>
          {profiles ? (
            <>
              {profile && <ProfileMenuItem profile={profile} isCurrent={profile.id === +(id ?? 0)} />}
              {profiles
                .filter(p => !p.isArchived)
                .filter(p => p.id !== profile?.id)
                .slice(0, 2)
                .map(p => (
                  <ProfileMenuItem profile={p} key={p.id} isCurrent={p.id === +(id ?? 0)} />
                ))}
            </>
          ) : (
            <LinearProgress />
          )}
          <CreateProfileMenuItem />
          <MainMenuProfileItem />
        </div>
      )}
    </>
  )
}
