import MUITextarea, { TextareaProps as MUITextareaProps } from '@mui/joy/Textarea'
import { FormControl, FormHelperText, FormLabel, Typography } from '@mui/joy'
import { ForwardedRef, forwardRef, useRef, useState } from 'react'
import { useFormFieldButton } from './useFormFieldButton'
import styles from './Textarea.module.sass'

interface TextareaProps {
  value?: string | number
  label?: string
  helperText?: string | React.ReactNode
  errors?: string[]
  initiallyTouched?: boolean
}

export const Textarea = forwardRef(
  (
    {
      value,
      onChange,
      label,
      helperText,
      errors,
      error,
      onKeyUp,
      initiallyTouched = false,
      ...rest
    }: TextareaProps & MUITextareaProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [touched, setTouched] = useState(initiallyTouched)
    return (
      <FormControl>
        {label && <FormLabel>{label}</FormLabel>}
        <MUITextarea
          {...rest}
          onBlur={e => {
            rest.onBlur?.(e)
            setTouched(true)
          }}
          onKeyUp={e => {
            setTouched(true)
            onKeyUp?.(e)
          }}
          value={value}
          onChange={onChange}
          ref={ref}
          error={error && touched}
        />
        {touched && errors?.[0] && <FormHelperText color="red">{errors[0]}</FormHelperText>}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  },
)

export const TextareaFormField = forwardRef(
  (props: TextareaProps & MUITextareaProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { isOpen, onBlur, onClick, isTouched } = useFormFieldButton({ isInitiallyTouched: !!props.initiallyTouched })

    const elRef = useRef<HTMLTextAreaElement | null>(null)

    return (
      <>
        {isOpen ? (
          <div className={styles.formOverlay}>
            {props.label && <Typography level="h4">{props.label}</Typography>}
            <Textarea
              {...props}
              onBlur={e => {
                onBlur()
                elRef.current = null
                props.onBlur?.(e)
              }}
              ref={el => {
                if (!el || elRef.current) {
                  return
                }
                const textarea = el.querySelector('textarea')
                elRef.current = textarea

                if (textarea) {
                  textarea.selectionStart = -1
                  textarea.focus()
                }
              }}
              size="lg"
              label={undefined}
            />
          </div>
        ) : (
          <Textarea
            {...props}
            initiallyTouched={isTouched}
            readOnly
            variant="soft"
            minRows={1}
            onClick={e => {
              elRef.current = null
              onClick()
              props.onClick?.(e)
            }}
          />
        )}
      </>
    )
  },
)
