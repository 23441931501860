import { createContext, useCallback, useState } from 'react'
import { PremiumAction, User } from '../models/User'
import { useSafeContext } from './hooks/useSafeContext'
import { getUserDetails, trackPremiumStats } from '../clients/UserClient'
import { PremiumIntro } from './PremiumIntro/PremiumIntro'

interface AppStateType {
  user: User
  setState?: React.Dispatch<React.SetStateAction<AppStateType>>
  trackPremiumStats: (action: PremiumAction) => void
  closePremiumIntro: () => void
  isPremiumIntroOpen: boolean
  refetchUser: () => Promise<void | null>
}

const AppStateContext = createContext<AppStateType | null>(null)

interface AppStateProps {
  children: React.ReactNode
  user: User
}
export const AppState = ({ children, user }: AppStateProps) => {
  const [isPremiumIntroOpen, setPremiumIntroOpen] = useState(false)
  const [state, setState] = useState<AppStateType>({
    user,
    refetchUser: () => Promise.resolve(null),
    isPremiumIntroOpen,
    trackPremiumStats: async action => {
      setPremiumIntroOpen(true)
      await trackPremiumStats(action)
    },
    closePremiumIntro: () => setPremiumIntroOpen(false),
  })

  const refetchUser = useCallback(
    () =>
      getUserDetails()
        .then(({ data }) => setState(prev => ({ ...prev, user: data })))
        .catch(() => {
          // todo
        }),

    [setState],
  )

  return (
    <AppStateContext.Provider value={{ ...state, setState, refetchUser }}>
      {isPremiumIntroOpen && <PremiumIntro />}
      {children}
    </AppStateContext.Provider>
  )
}

export const useAppState = () => {
  const { setState, ...appState } = useSafeContext(AppStateContext)
  // todo some further logic here

  return {
    ...appState,
  }
}

export const AppMountingContext = createContext<{ reloadApp: () => void } | null>(null)

export const AppMountingContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [counter, setCounter] = useState(0)
  const reloadApp = useCallback(() => {
    setCounter(prev => prev + 1)
  }, [setCounter])

  return (
    <AppMountingContext.Provider value={{ reloadApp }} key={counter}>
      {children}
    </AppMountingContext.Provider>
  )
}

export const useAppMounting = () => {
  const { reloadApp } = useSafeContext(AppMountingContext)

  return reloadApp
}
