import { tg } from '../../App'
import { Button } from '../../ui/Button/Button'
import { Card } from '../../ui/Card/Card'

export const PrivacyPolicyPage = () => {
  return (
    <Card title="Политика обработки персональных данных">
      <Button
        fullWidth
        onClick={() => {
          tg.openLink('https://relevants.space/pd.docx')
        }}
      >
        Скачать документ
      </Button>
    </Card>
  )
}
