import { useCallback, useEffect, useState } from 'react'
import { useSafeContext } from './hooks/useSafeContext'
import { MainButtonContext } from './MainButtonContext'
import { FormButtonContext } from './FormButtonContext'

export const useFormFieldButton = ({ isInitiallyTouched }: { isInitiallyTouched: boolean }) => {
  const [isTouched, setTouched] = useState(isInitiallyTouched)
  const [isOpen, setOpen] = useState(false)
  const { hide, show, onClick: addClickHandler, offClick, setParams } = useSafeContext(MainButtonContext)
  const { removeHandler, resetToDefault, setFormOpen } = useSafeContext(FormButtonContext)

  const clickFunction = useCallback(() => {
    setOpen(false)
    setFormOpen(false)
  }, [setFormOpen])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      addClickHandler(clickFunction)
      removeHandler()
      setParams({ text: 'Сохранить', color: '#f0f4f7', text_color: '#000000' })
    } else {
      document.body.style.overflow = 'initial'
      offClick(clickFunction)
      resetToDefault()
    }
  }, [isOpen, hide, addClickHandler, show, clickFunction, setParams, offClick, removeHandler, resetToDefault])

  const onClick = useCallback(() => {
    document.body.style.overflow = 'hidden'
    setTouched(true)
    setOpen(true)
    setFormOpen(true)
  }, [setFormOpen, setTouched])

  return {
    isOpen,
    onBlur: clickFunction,
    onClick,
    isTouched,
  }
}
