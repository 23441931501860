import { useEffect, useState } from 'react'
import { ProfileCreationLog, ProfileVariant } from '../../models/Profile'
import { getProfileCreationLogs } from '../../clients/AdminClient'
import { Button, LinearProgress, Step, StepIndicator, Stepper, Typography } from '@mui/joy'
import { Card } from '../../ui/Card/Card'
import styles from './AdminCreateProfileLogsPage.module.sass'
import { jobStepsNames, resumeStepsNames } from '../CreateProfile/createProfileSteps'
import { useNavigate } from 'react-router-dom'
import { Check, Close } from '@mui/icons-material'

export const AdminCreateProfileLogsPage = () => {
  const [groups, setGroups] = useState<ProfileCreationLog[][]>()

  useEffect(() => {
    getProfileCreationLogs().then(r => setGroups(r.data))
  }, [setGroups])

  const navigate = useNavigate()

  return (
    <div className={styles.wrapper}>
      {groups ? (
        groups.map(logs => (
          <Card title={`Создает ${logs.at(0)?.variant}`}>
            <Stepper orientation="vertical">
              {logs.map((log, i) => (
                <Step
                  key={log.id}
                  indicator={
                    <StepIndicator variant="soft" color={log.step !== -1 ? 'danger' : 'success'}>
                      {log.step !== -1 ? i === logs.length - 1 ? <Close /> : log.step + 1 : <Check />}
                    </StepIndicator>
                  }
                >
                  {log.variant === ProfileVariant.Job ? jobStepsNames[log.step] : resumeStepsNames[log.step]}
                  {log.step === -1 && 'Создал профиль'}
                  <Typography fontSize="sm" color="neutral">
                    {log.createdAt}
                  </Typography>
                  {i === logs.length - 1 && (
                    <>
                      {log.step !== -1 ? (
                        <Typography fontSize="sm" color="danger">
                          Не досоздал профиль
                        </Typography>
                      ) : (
                        <Typography fontSize="sm" color="success">
                          Создал профиль
                        </Typography>
                      )}
                      <Button onClick={() => navigate('/admin/user/' + log.userId)}>Связаться ID#{log.userId}</Button>
                    </>
                  )}
                </Step>
              ))}
            </Stepper>
          </Card>
        ))
      ) : (
        <LinearProgress />
      )}
    </div>
  )
}
