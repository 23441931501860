import { PropsWithChildren, createContext, useState } from 'react'

type FormButtonContextState = {
  removeHandler: () => void
  resetToDefault: () => void
  isFormOpen: boolean
  setFormOpen: (val: boolean) => void
}

export const FormButtonContext = createContext<FormButtonContextState | null>(null)

export const FormButtonContextProvider = ({
  children,
  ...value
}: PropsWithChildren<Pick<FormButtonContextState, 'removeHandler' | 'resetToDefault'>>) => {
  const [isFormOpen, setFormOpen] = useState(false)

  return (
    <FormButtonContext.Provider value={{ ...value, isFormOpen, setFormOpen }}>{children}</FormButtonContext.Provider>
  )
}
