import axios from 'axios'
import config from '../config'
import { PremiumAction, User } from '../models/User'
import { ProfileCompact } from '../models/Profile'

const userUrl = (path: string) => `${config.API_URL}/user/${path}`

export const validateUser = (checkDataString: string) =>
  axios.post<{ token: string; user: User }>(userUrl('validate'), { checkDataString })

export const getUserDetails = () => axios.get<User>(userUrl('details'))

export const updateUserDetails = (userDetails: { personName: string; birthYear: number }) =>
  axios.put<User>(userUrl('update'), userDetails)

export const getMyProfiles = () => axios.get<ProfileCompact[]>(userUrl('profiles'))

export const acceptTOS = () => axios.post(userUrl('accept-tos'))

export const passOnboarding = () => axios.post(userUrl('pass-onboarding'))

export const canCreateProfile = () =>
  axios.get<{ canCreateJobProfile: boolean; canCreateResumeProfile: boolean }>(userUrl('can-create-profile'))

export const trackPremiumStats = (action: PremiumAction) => axios.post(userUrl('track-premium'), { action })
