import axios from 'axios'
import config from '../config'
import { ProfileQueueItem } from '../models/Profile'

const queueUrl = (path: string) => `${config.API_URL}/queue/${path}`

export const initSearchQueue = (profileId: number) => axios.post<ProfileQueueItem[]>(queueUrl('init'), { profileId })

export interface QueueFeedback {
  rating: number
  comment?: string
  profileId: number
}

export enum QueueSwipeResult {
  LIKE = 'LIKE',
  SKIP = 'SKIP',
}

export const sendFeedback = (feedback: QueueFeedback) => axios.post(queueUrl('feedback'), feedback)

export const swipeProfile = (profileId: number, swipeProfileId: number, result: QueueSwipeResult) =>
  axios.post<{ isMutualLike: boolean }>(queueUrl('swipe'), {
    profileId,
    swipeProfileId,
    result,
  })
