import { Reducer, useCallback, useEffect, useReducer, useState } from 'react'
import Joi from '@hapi/joi'

type FormAction<T> =
  | {
      type: 'set'
      payload: {
        name: keyof T
        value: T[keyof T]
      }
    }
  | {
      type: 'update'
      payload: {
        newState: T
      }
    }

const reducer = <T>(state: T, action: FormAction<T>) => {
  switch (action.type) {
    case 'set':
      return { ...state, [action.payload.name]: action.payload.value }
    case 'update':
      return { ...action.payload.newState }
  }
}

export const useForm = <T extends {}>({
  initialArgs = {} as T,
  validationSchema,
}: {
  initialArgs?: T
  validationSchema?: Partial<Record<keyof T, any>>
}) => {
  const [state, dispatch] = useReducer<Reducer<T, FormAction<T>>>(reducer, initialArgs)
  const [errors, setErrors] = useState<Partial<Record<keyof T, string>>>()

  useEffect(() => {
    if (!validationSchema) {
      return
    }

    const { error } = Joi.object(validationSchema).validate(state, { abortEarly: false })
    setErrors(
      error?.details.reduce<Partial<Record<keyof T, string>>>((errors, current) => {
        const key = current.path[0] as keyof T

        errors[key] = current.message

        return errors
      }, {}) ?? {},
    )
  }, [state, validationSchema])

  const onChange: <K extends keyof T>(name: K) => (value: T[K]) => void = useCallback(
    name => value => {
      dispatch({
        type: 'set',
        payload: {
          name,
          value,
        },
      })
    },
    [dispatch],
  )

  const setState = useCallback((newState: T) => {
    dispatch({
      type: 'update',
      payload: {
        newState,
      },
    })
  }, [])

  return {
    state,
    onChange,
    setState,
    errors,
  }
}
