import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import styles from './StatsSlider.module.sass'
import { useEffect, useMemo, useState } from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { ProfileDayStats } from '../../models/Profile'
import { getProfileWeeklyStats } from '../../clients/ProfileClient'
import { CircularProgress } from '@mui/joy'
import _ from 'lodash'

type Data = {
  name: string
  value: number
}

const StatsChart = ({ data, fill = '#E1CBFF' }: { data?: Data[] | null; fill?: string }) => {
  return (
    <div className={styles.chartWrapper}>
      {data === undefined && <CircularProgress />}
      {data === null && 'Невозможно загрузить статистику...'}
      {data && (
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              left: -40,
              bottom: -10,
            }}
          >
            <YAxis
              allowDecimals={false}
              tickLine={false}
              axisLine={false}
              tick={{ fontSize: 12, fill: '#B9B9C6' }}
              tickCount={3}
            />
            <XAxis dataKey="name" tickLine={false} axisLine={false} tick={{ fontSize: 12, fill: '#B9B9C6' }} />
            <CartesianGrid vertical={false} strokeDasharray="0 0" strokeOpacity={0.6} />
            <Bar barSize={28} dataKey="value" fill={fill} radius={[8, 8, 8, 8]} fillOpacity={1} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}

const dateToName = (date: Date) => {
  return date.toLocaleDateString('ru-RU', { weekday: 'short' })
}

const dotCn = (isActive: boolean) => styles.dot + (isActive ? ` ${styles.active}` : ``)

interface StatsSliderProps {
  profileId: number
}
export const StatsSlider = ({ profileId }: StatsSliderProps) => {
  const [index, setIndex] = useState(0)
  const [stats, setStats] = useState<ProfileDayStats[] | null>()

  const dates = useMemo(
    () =>
      _.range(6, -1, -1).map(i => {
        const date = new Date()
        date.setDate(date.getDate() - i)

        const statEntry = stats?.find(s => new Date(s.date).getDate() === date.getDate())

        return {
          name: dateToName(date),
          likesCount: statEntry?.likesCount ?? 0,
          viewsCount: (statEntry?.likesCount ?? 0) + (statEntry?.skipsCount ?? 0),
        }
      }),
    [stats],
  )

  useEffect(() => {
    getProfileWeeklyStats(profileId)
      .then(({ data }) => {
        setStats(data.map(s => ({ ...s, date: new Date(s.date) })))
      })
      .catch(() => {
        setStats(null)
      })
  }, [profileId])

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Swiper
        slidesPerView={1}
        className={styles.swiper}
        spaceBetween={20}
        centeredSlides
        onSlideChange={swiper => setIndex(swiper.activeIndex)}
      >
        <SwiperSlide className={styles.slideWrapper}>
          <div className={styles.content}>
            <span>Статистика показов</span>
            <StatsChart data={dates.map(d => ({ name: d.name, value: d.viewsCount }))} />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.slideWrapper}>
          <div className={styles.content}>
            <span>Статистика лайков</span>
            <StatsChart fill="#D7F5DD" data={dates.map(d => ({ name: d.name, value: d.likesCount }))} />
          </div>
        </SwiperSlide>
      </Swiper>
      <div className={styles.pagination}>
        <div className={dotCn(index === 0)} />
        <div className={dotCn(index === 1)} />
      </div>
    </div>
  )
}
