import { useContext } from 'react'

export const useSafeContext = <T>(context: React.Context<T | null>): T => {
  const safeContext = useContext(context)
  if (!safeContext) {
    throw Error('Context consumer must be called from Provider')
  }

  return safeContext
}
