import { ButtonProps } from '@mui/joy'
import { Button } from './Button'
import styles from './BackButton.module.sass'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

export const BackButton = (props: ButtonProps) => {
  return (
    <Button variant="outlined" {...props} className={styles.backButton}>
      <ChevronLeftIcon />
    </Button>
  )
}
