import axios from 'axios'
import config from '../config'
import { ProfileCompact, ProfileCreationLog, ProfileExplicit } from '../models/Profile'
import { UserExplicit, UserId } from '../models/User'

const adminUrl = (path: string) => `${config.API_URL}/admin/${path}`

export const getProfiles = () => axios.get<ProfileCompact[]>(adminUrl('profiles'))
export const getProfileDetails = (profileId: number) => axios.get<ProfileExplicit>(adminUrl('profile/') + profileId)

export const suspendProfile = (profileId: number, isShadow: boolean) =>
  axios.post(adminUrl(!isShadow ? 'profile/suspend' : 'profile/suspicious'), { profileId })
export const restoreSuspendedProfile = (profileId: number, isShadow: boolean) =>
  axios.post(adminUrl(!isShadow ? 'profile/restore-suspended' : 'profile/restore-suspicious'), { profileId })

export const removeProfileFromSearch = (profileId: number) =>
  axios.post(adminUrl('profile/remove-from-search'), { profileId })

export const addProfileToSearch = (profileId: number) => axios.post(adminUrl('profile/add-to-search'), { profileId })

export const getProfileCategories = () => axios.get<{ id: number; name: string }[]>(adminUrl('profile/categories'))
export const addProfileCategory = (data: { profileId: number; category: string }) =>
  axios.post(adminUrl('profile/add-category'), data)

export const getProfilePositionNames = () =>
  axios.get<{ id: number; name: string }[]>(adminUrl('profile/position-names'))
export const addProfilePositionName = (data: { profileId: number; name: string }) =>
  axios.post(adminUrl('profile/add-position-name'), data)

export const getUsers = () => axios.get<UserExplicit[]>(adminUrl('users'))
export const getUserDetails = (userId: UserId) => axios.get<UserExplicit>(adminUrl('user/' + userId))

export const getContact = (data: { personName: string; telegramId: number }) =>
  axios.post(adminUrl('user/get-contact'), data)

export const getProfileCreationLogs = () => axios.get<ProfileCreationLog[][]>(adminUrl('profile-creation-logs'))
