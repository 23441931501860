import { ProfileCompact, ProfileQueueItem } from '../../models/Profile'
import { useCallback, useEffect, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { ProfileDetails } from '../ProfileDetails/ProfileDetails'
import { TopBackLink } from '../TopBackLink/TopBackLink'
import styles from './ProfileStack.module.sass'
import { NoResultsCard, ProfileCard, RateCard } from './ProfilesStackCards'
import { useSafeContext } from '../hooks/useSafeContext'
import { ProfileContext } from '../../pages/ProfilePage/ProfilePage'
import similarity from 'similarity'
import { Button, Divider, Modal, ModalClose, ModalDialog, Typography } from '@mui/joy'
import { Avatar } from '../Avatar/Avatar'
import { QueueSwipeResult, swipeProfile } from '../../clients/QueueClient'
import { profileToString } from '../../tools/StringUtil'
import { tg } from '../../App'
import { useAppState } from '../AppState'
import { StackOnboarding } from './StackOnboarding'

export const MatchModal = ({
  profiles: [ownProfile, targetProfile],
  onClose,
}: {
  profiles: [ProfileCompact, ProfileQueueItem]
  onClose: () => void
}) => {
  return (
    <Modal open onClose={onClose}>
      <ModalDialog>
        <ModalClose style={{ right: 0, top: -32 }} />
        <div className={styles.avatars}>
          <div>
            <Avatar profilePicture={ownProfile.profilePicture} />
          </div>
          <div style={{ marginLeft: -32 }}>
            <Avatar profilePicture={targetProfile.profilePicture} />
          </div>
        </div>
        <Typography fontSize={19} component="h1" className={styles.matchLabel}>
          У вас есть взаимный лайк!
        </Typography>
        <Divider />
        <Typography fontSize={16} textColor="text.tertiary" className={styles.matchText}>
          Мы отправили контакт пользователя вам в Telegram
        </Typography>
        <div className={styles.matchModalButtons}>
          <Button variant="soft" color={'info' as any} fullWidth onClick={() => tg.close()}>
            Перейти в Telegram
          </Button>
          <Button variant="soft" color="neutral" fullWidth onClick={onClose}>
            Продолжить просмотр
          </Button>
        </div>
      </ModalDialog>
    </Modal>
  )
}

interface ProfileStackProps {
  profiles?: ProfileQueueItem[] | null
  refetch: () => void
  incomingLikesTab?: boolean
}
export const ProfilesStack = ({ profiles: propsProfiles, refetch, incomingLikesTab }: ProfileStackProps) => {
  const [profiles, setProfiles] = useState<(ProfileQueueItem | 'rate' | 'noCardsLeft' | 'noResults')[]>([
    ...(propsProfiles ?? []),
    ...((propsProfiles?.length ? ['rate'] : []) as any),
    'noCardsLeft',
  ])

  const [animate, setAnimate] = useState<'hideLike' | 'hideSkip' | 'new' | null>(null)
  const [isProfileOpen, setProfileOpen] = useState(false)

  const [isOnboardingOpen, setOnboardingOpen] = useState(true)

  useEffect(() => {
    if (profiles[0] === 'noCardsLeft') {
      refetch()
    }
  }, [profiles, refetch])

  useEffect(() => {
    setProfiles([...(propsProfiles ?? []), ...((propsProfiles?.length ? ['rate'] : []) as any), 'noCardsLeft'])
    if (propsProfiles === null) {
      setProfiles(['noResults'])
    }
  }, [propsProfiles, setProfiles])

  const { profile: ownProfile, refreshProfile } = useSafeContext(ProfileContext)
  const currentProfile = profiles[0]

  const onSwipedUp = useCallback(
    (isLike: boolean = false) => {
      if (profiles[0] === 'noCardsLeft' || profiles[0] === 'noResults' || animate) {
        return
      }

      if (!isLike && typeof currentProfile === 'object' && currentProfile.id) {
        swipeProfile(ownProfile.id, currentProfile.id, QueueSwipeResult.SKIP).then(() => {
          refreshProfile()
        })
      }

      setAnimate(isLike ? 'hideLike' : 'hideSkip')
      setTimeout(() => {
        setAnimate('new')
      }, 500)

      setTimeout(() => {
        setProfiles(prev => prev.slice(1))
        setAnimate(null)
      }, 800)
    },
    [profiles, animate, currentProfile, ownProfile.id, refreshProfile],
  )

  const { ref } = useSwipeable({
    onSwipedUp: () => onSwipedUp(),
    delta: 100,
  })

  const [isLikeOverlayOpen, setLikeOverlayOpen] = useState(false)
  const [matchedProfile, setMatchedProfile] = useState<number>()

  const { user } = useAppState()

  const onLike = (profileId: number) => {
    setLikeOverlayOpen(true)
    onSwipedUp(true)
    swipeProfile(ownProfile.id, profileId, QueueSwipeResult.LIKE).then(({ data }) => {
      refreshProfile()
      if (data.isMutualLike) {
        setMatchedProfile(profileId)
      }
    })

    setTimeout(() => {
      setLikeOverlayOpen(false)
    }, 250)
  }

  const matchP = propsProfiles?.find(p => p.id === matchedProfile)

  const MatchModalWrapper = () => (
    <>{matchP && <MatchModal onClose={() => setMatchedProfile(undefined)} profiles={[ownProfile, matchP]} />}</>
  )

  const cardContent = (profile: ProfileQueueItem | 'rate' | 'noCardsLeft' | 'noResults') => {
    if (profile === 'rate') {
      return <RateCard swipeUp={onSwipedUp} />
    }

    if (profile === 'noCardsLeft' || profile === 'noResults') {
      return <NoResultsCard noLikes={incomingLikesTab} noResults={profile === 'noResults'} />
    }

    return (
      <ProfileCard
        isOverlayOpen={isLikeOverlayOpen}
        onLike={onLike}
        onSwipedUp={onSwipedUp}
        profile={profile}
        openProfile={() => setProfileOpen(true)}
      />
    )
  }

  if (
    isProfileOpen &&
    currentProfile !== 'noCardsLeft' &&
    currentProfile !== 'rate' &&
    currentProfile !== 'noResults'
  ) {
    return (
      <>
        <MatchModalWrapper />
        <TopBackLink onClick={() => setProfileOpen(false)} />
        <div style={{ paddingTop: 60, width: '100%' }}>
          <ProfileDetails
            profile={currentProfile}
            onLike={profileId => {
              setProfileOpen(false)
              onLike(profileId)
            }}
            match={+(similarity(profileToString(currentProfile), profileToString(ownProfile)) * 100).toFixed(0)}
          />
        </div>
      </>
    )
  }

  return (
    <div className={styles.stackWrapper}>
      {!user.isOnboardingPassed && typeof currentProfile === 'object' && isOnboardingOpen && (
        <StackOnboarding onClose={() => setOnboardingOpen(false)} />
      )}
      <MatchModalWrapper />
      {profiles.map((profile, i) => (
        <div
          style={{
            zIndex: profiles.length - i || 1,
            display: i > 1 || (i === 0 && animate === 'new') ? 'none' : 'inherit',
          }}
          className={
            (i === 1 ? styles.bottom + ' ' : ' ') +
            styles.cardWrapper +
            (i === 0 && animate === 'hideLike'
              ? ' ' + styles.animateHideLike
              : i === 0 && animate === 'hideSkip'
              ? ' ' + styles.animateHideSkip
              : i === 1 && animate === 'new'
              ? ' ' + styles.newCardAnimate
              : '')
          }
          ref={i === 0 ? ref : undefined}
          key={i}
        >
          {cardContent(profile)}
        </div>
      ))}
    </div>
  )
}
