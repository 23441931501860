import { ProfileCompact, ProfileQueueItem } from '../models/Profile'

export const a = () => {}

export const getPristineString = (s: string) => {
  return s.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' ')
}

export const birthYearToAge = (birthYear?: number) => birthYear && new Date().getFullYear() - birthYear

export const ageToBirthYear = (age?: number) => age && new Date().getFullYear() - age

export const plural = (n: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[n % 100 > 4 && n % 100 < 20 ? 2 : cases[n % 10 < 5 ? n % 10 : 5]]
}

export const profileToString = (profile: ProfileQueueItem | ProfileCompact) =>
  `${profile.position}${profile.description}${profile.lookingForDescription}${profile.tags?.join('')}`
