import styles from './BottomNavigationItem.module.sass'

export const BottomNavigationItem = ({
  children,
  onClick,
  disabled = false,
}: {
  children: React.ReactNode
  onClick?: () => void
  disabled?: boolean
}) => {
  return (
    <div
      className={styles.itemWrapper + (disabled ? ' ' + styles.disabled : '')}
      onClick={() => !disabled && onClick?.()}
    >
      {children}
    </div>
  )
}
