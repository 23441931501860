import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from '../../ui/hooks/useForm'
import { createStepperContext } from '../../ui/Stepper/Stepper'
import { Stepper } from '../../ui/Stepper/Stepper'
import { acceptTOS, updateUserDetails } from '../../clients/UserClient'
import Joi from '@hapi/joi'
import { createUserSteps } from './createUserSteps'
import { useNavigate } from 'react-router-dom'
import { useAppMounting, useAppState } from '../../ui/AppState'
import { useSafeContext } from '../../ui/hooks/useSafeContext'
import { MainButtonContext } from '../../ui/MainButtonContext'
import { isIOS } from '../../tools/ClientUtil'
import { tg } from '../../App'
import { FormButtonContextProvider } from '../../ui/FormButtonContext'
import { useLatestRef } from '../../hooks/useLatestRef'

interface UpdateUser {
  personName: string
  birthYear: number
}

interface RegisterPageContextType {
  state: Partial<UpdateUser>
  onChange: <K extends keyof UpdateUser>(name: K) => (value: Partial<UpdateUser>[K]) => void
  onRegister: () => void
  errors: Partial<Record<keyof UpdateUser, string>>
  next: () => void
  back: () => void
}
export const RegisterPageContext = createStepperContext<RegisterPageContextType | null>(null)

export const RegisterPage = () => {
  const validationSchema = useMemo(
    () => ({
      personName: Joi.string().trim().min(2).max(12).required().empty(),
      birthYear: Joi.number()
        .min(1930)
        .max(new Date().getFullYear() - 18)
        .required(),
    }),
    [],
  )

  const { state, onChange, errors } = useForm<Partial<UpdateUser>>({
    validationSchema,
    initialArgs: {
      personName: tg?.initDataUnsafe?.user?.first_name ?? undefined,
    },
  })
  const [currentStep, setCurrentStep] = useState<number | undefined>(0)

  const navigate = useNavigate()

  const { user, refetchUser } = useAppState()
  const reloadApp = useAppMounting()

  const { show, hide, enable, hideProgress, showProgress, setParams, onClick, offClick } =
    useSafeContext(MainButtonContext)

  const userRef = useLatestRef(state)

  const onRegister = useCallback(() => {
    if (isIOS()) {
      showProgress(false)
    }
    updateUserDetails(userRef.current as any)
      .then(() => {
        navigate('/create-profile')
        reloadApp()
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        if (isIOS()) {
          hideProgress()
          enable()
        }
      })
  }, [showProgress, navigate, reloadApp, hideProgress, enable, userRef])

  const isLastStep = createUserSteps.slice(user.hasAcceptedTOS ? 1 : 0).length - 1 === currentStep

  const callBack = useCallback(() => {
    if (!user.hasAcceptedTOS && currentStep === 0) {
      showProgress(false)
      acceptTOS()
        .then(() => {
          refetchUser()
        })
        .finally(() => {
          hideProgress()
          enable()
        })
    }

    if (user.hasAcceptedTOS && isLastStep) {
      onRegister()
    }

    if (user.hasAcceptedTOS && !isLastStep) {
      setCurrentStep(prev => (prev !== undefined ? (prev < createUserSteps.length ? prev + 1 : prev) : undefined))
    }
  }, [currentStep, enable, hideProgress, isLastStep, onRegister, refetchUser, showProgress, user.hasAcceptedTOS])

  const buttonParams = useMemo(
    () => ({
      text: isLastStep ? 'Зарегистрироваться' : 'Продолжить',
      text_color: '#054DA7',
      color: '#DDF1FF',
    }),
    [isLastStep],
  )

  const resetButtonHandler = useCallback(() => {
    offClick(callBack)
  }, [callBack, offClick])

  const resetToDefaultButton = useCallback(() => {
    if (currentStep !== undefined && currentStep !== null) {
      setParams(buttonParams)
      onClick(callBack)
    }
  }, [currentStep, setParams, buttonParams, onClick, callBack])

  useEffect(() => {
    resetToDefaultButton()
    return () => resetButtonHandler()
  }, [resetToDefaultButton, resetButtonHandler])

  useEffect(() => {
    show()
    return () => {
      enable()
      hide()
    }
  }, [enable, hide, show])

  useEffect(() => {
    document.body.style.background = '#fff'
    return () => {
      document.body.style.background = '#f7f7f8'
    }
  }, [])

  return (
    <RegisterPageContext.Provider
      value={{
        onChange,
        state,
        errors: errors ?? {},
        onRegister,
        next: () =>
          setCurrentStep(prev => (prev !== undefined ? (prev < createUserSteps.length ? prev + 1 : prev) : undefined)),
        back: () => setCurrentStep(prev => (prev !== undefined ? (prev > 0 ? prev - 1 : prev) : undefined)),
      }}
    >
      <FormButtonContextProvider removeHandler={resetButtonHandler} resetToDefault={resetToDefaultButton}>
        <Stepper
          currentStep={currentStep ?? 0}
          steps={createUserSteps.slice(user.hasAcceptedTOS ? 1 : 0)}
          setStep={setCurrentStep}
        />
      </FormButtonContextProvider>
    </RegisterPageContext.Provider>
  )
}
