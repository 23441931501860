import { Outlet, useNavigate } from 'react-router-dom'
import { PageLayout } from '../../ui/PageLayout/PageLayout'
import { TopBackLink } from '../../ui/TopBackLink/TopBackLink'
import { List, ListItem } from '@mui/joy'
import { ChevronRightIcon } from '../../icons/ChevronRightIcon'
import styles from './AdminPage.module.sass'

export const AdminPage = () => {
  return (
    <PageLayout title="Админ" topMenu>
      <TopBackLink />
      <Outlet />
    </PageLayout>
  )
}

export const AdminMenuPage = () => {
  const navigate = useNavigate()
  return (
    <List
      variant="outlined"
      sx={{
        bgcolor: 'background.body',
        minWidth: 240,
        borderRadius: '8px',
        boxShadow: '1px 2px 12px 0px rgba(187, 187, 187, 0.18)',
        border: 'none',
      }}
    >
      <ListItem onClick={() => navigate('/admin/profiles')} sx={{ height: 44 }}>
        <span className={styles.profileListItemTile}>
          Все профили
          <ChevronRightIcon />
        </span>
      </ListItem>
      {/* <ListItem onClick={() => navigate('/admin/swipes')} sx={{ height: 44 }}>
        <span className={styles.profileListItemTile}>
          Все свайпы
          <ChevronRightIcon />
        </span>
      </ListItem> */}
      <ListItem onClick={() => navigate('/admin/users')} sx={{ height: 44 }}>
        <span className={styles.profileListItemTile}>
          Все пользователи
          <ChevronRightIcon />
        </span>
      </ListItem>
      <ListItem onClick={() => navigate('/admin/profile-creation')} sx={{ height: 44 }}>
        <span className={styles.profileListItemTile}>
          create_profile_activity_logs
          <ChevronRightIcon />
        </span>
      </ListItem>
    </List>
  )
}
