import axios from 'axios'
import config from '../config'
import { CroppedType } from '../ui/ImageCrop/ImageCrop'

const uploadUrl = (path: string) => `${config.API_URL}/upload/${path}`

export const uploadImage = (file: File, croppedAreas: CroppedType) => {
  const form = new FormData()
  form.append('image', file)
  form.append('crop', JSON.stringify(croppedAreas.croppedAreaPixels))

  return axios.post<string>(uploadUrl('image'), form)
}
