import styles from './ContractedPlaceholder.module.sass'

export const ContractedPlaceholder = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <h2 className={styles.title}>Пожалуйста, откройте приложение во весь экран</h2>
      </div>
    </div>
  )
}
