import styles from './Card.module.sass'

interface CardProps {
  children: React.ReactNode
  title?: string
  rightTitleContent?: () => JSX.Element
}
export const Card = ({ children, title, rightTitleContent }: CardProps) => {
  return (
    <div className={styles.cardWrapper}>
      {(title || rightTitleContent) && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>
          <span className={styles.title}>{title}</span>
          {rightTitleContent?.()}
        </div>
      )}
      {children}
    </div>
  )
}
