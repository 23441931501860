import { useCallback, useEffect, useState } from 'react'
import { PageLayout } from '../../ui/PageLayout/PageLayout'
import { ProfileQueueItem } from '../../models/Profile'
import { useSafeContext } from '../../ui/hooks/useSafeContext'
import { ProfileContext } from './ProfilePage'
import { ProfilesStack } from '../../ui/ProfilesStack/ProfilesStack'
import { getProfileIncomingLikes } from '../../clients/ProfileClient'
import styles from './ProfileSearchResults.module.sass'

export const ProfileLikesPage = () => {
  const { profile } = useSafeContext(ProfileContext)

  const [results, setResults] = useState<ProfileQueueItem[] | null>()

  const refetch = useCallback(() => {
    setResults(undefined)
  }, [setResults])

  useEffect(() => {
    if (results !== undefined) {
      return
    }
    getProfileIncomingLikes(profile.id)
      .then(({ data }) => {
        if (!data.length) {
          return setResults(null)
        }
        setResults(data)
      })
      .catch(() => setResults(null))
  }, [profile.id, results])

  return (
    <PageLayout className={styles.mainWrapper} title={`${profile.position ?? 'Загрузка профиля...'} - Входящие лайки`}>
      <ProfilesStack profiles={results} refetch={refetch} incomingLikesTab />
    </PageLayout>
  )
}
