export const SuccessIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect y="0.5" width="44" height="44" rx="22" fill="#F7F7F8" />
    <path
      d="M19.8399 27.58C19.7085 27.5801 19.5784 27.5543 19.4571 27.504C19.3358 27.4537 19.2256 27.38 19.1329 27.287L15.293 23.4471C15.1993 23.3545 15.1248 23.2442 15.0739 23.1227C15.0229 23.0012 14.9965 22.8707 14.9961 22.739C14.9957 22.6072 15.0214 22.4766 15.0717 22.3548C15.1219 22.2329 15.1958 22.1223 15.2889 22.0291C15.3821 21.9359 15.4928 21.862 15.6146 21.8118C15.7365 21.7615 15.867 21.7358 15.9988 21.7362C16.1306 21.7366 16.261 21.763 16.3826 21.8139C16.5041 21.8649 16.6144 21.9394 16.707 22.0331L19.8399 25.1659L27.293 17.7128C27.4807 17.5259 27.7348 17.4211 27.9996 17.4214C28.2645 17.4217 28.5184 17.527 28.7056 17.7143C28.8929 17.9015 28.9982 18.1555 28.9985 18.4203C28.9987 18.6851 28.8939 18.9392 28.707 19.1269L20.5469 27.287C20.4541 27.38 20.3439 27.4538 20.2226 27.504C20.1013 27.5543 19.9712 27.5801 19.8399 27.58V27.58Z"
      fill="url(#paint0_linear_512_38256)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_512_38256"
        x1="19.5969"
        y1="20.469"
        x2="23.5481"
        y2="26.8229"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3D73EB" />
        <stop offset="1" stopColor="#DE8FFF" />
      </linearGradient>
    </defs>
  </svg>
)
