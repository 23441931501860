import { isIOS } from '../../tools/ClientUtil'
import styles from './StepperBottomMenu.module.sass'

interface StepperBottomMenuProps {
  children: React.ReactNode
  className?: string
  anyDevice?: boolean
}
export const StepperBottomMenu = ({ children, className, anyDevice = false }: StepperBottomMenuProps) => {
  if (isIOS() && !anyDevice) {
    return null
  }

  return <div className={styles.root + `${className ? ' ' + className : ''}`}>{children}</div>
}
