import { useEffect, useState } from 'react'
import { PageLayout } from '../../ui/PageLayout/PageLayout'
import { ProfileCompact } from '../../models/Profile'
import { getMyProfiles } from '../../clients/UserClient'
import { ProfilesList, SettingsList } from '../../ui/ProfilesList/ProfilesList'
import styles from './MainPage.module.sass'
import { LinearProgress } from '@mui/joy'

export const MainPage = () => {
  const [profiles, setProfiles] = useState<ProfileCompact[]>()
  const [isArchivedOnly, setArchivedOnly] = useState(false)

  useEffect(() => {
    getMyProfiles()
      .then(({ data }) => setProfiles(data))
      .catch(e => console.log(e))
  }, [setProfiles])

  return (
    <PageLayout title="Relevants">
      {!profiles ? (
        <LinearProgress />
      ) : (
        <div className={styles.sectionsWrapper}>
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <span>Аккаунт</span>
            </div>
            <SettingsList isOpen={false} />
          </div>
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <span className={isArchivedOnly ? styles.notActive : undefined} onClick={() => setArchivedOnly(false)}>
                Профили
              </span>
              <span className={!isArchivedOnly ? styles.notActive : undefined} onClick={() => setArchivedOnly(true)}>
                Архив
              </span>
            </div>
            <ProfilesList profiles={profiles.filter(p => (isArchivedOnly ? p.isArchived : !p.isArchived))} />
          </div>
        </div>
      )}
    </PageLayout>
  )
}
