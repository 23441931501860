export const StarEmptyIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="44" height="44" rx="22" fill="#F7F7F8" />
    <path
      d="M27.5627 31.0593L27.5566 31.0593C27.4776 31.0583 27.3999 31.0386 27.3299 31.002C27.3298 31.0019 27.3297 31.0019 27.3295 31.0018L22.2328 28.3223L22.0001 28.2L21.7675 28.3223L16.6703 31.002C16.6703 31.002 16.6703 31.002 16.6703 31.002C16.5877 31.0454 16.4946 31.0648 16.4016 31.0581C16.3085 31.0514 16.2191 31.0188 16.1436 30.9639C16.0681 30.9091 16.0095 30.8342 15.9743 30.7478C15.9392 30.6614 15.9289 30.5669 15.9446 30.4749C15.9446 30.4749 15.9446 30.4748 15.9446 30.4748L16.9182 24.7991L16.9627 24.54L16.7744 24.3565L12.651 20.3377C12.5842 20.2725 12.5369 20.1899 12.5146 20.0993C12.4922 20.0087 12.4956 19.9137 12.5245 19.8249C12.5533 19.7361 12.6064 19.6572 12.6777 19.597C12.749 19.5369 12.8357 19.4979 12.928 19.4844C12.9281 19.4844 12.9281 19.4844 12.9282 19.4844L18.6267 18.6563L18.8869 18.6185L19.0032 18.3827L21.5418 13.2386C21.5892 13.1636 21.6543 13.1012 21.7316 13.0571C21.8134 13.0104 21.9059 12.9858 22.0001 12.9858C22.0943 12.9858 22.1868 13.0104 22.2686 13.0571C22.3459 13.1012 22.411 13.1636 22.4584 13.2387L24.997 18.3827L25.1134 18.6184L25.3735 18.6562L31.072 19.4843C31.0721 19.4843 31.0721 19.4843 31.0722 19.4843C31.0722 19.4843 31.0722 19.4843 31.0722 19.4843C31.1645 19.4978 31.2512 19.5368 31.3225 19.5969C31.3938 19.6571 31.4469 19.736 31.4757 19.8248C31.5046 19.9136 31.508 20.0086 31.4857 20.0992C31.4633 20.1898 31.4161 20.2723 31.3493 20.3374C31.3493 20.3375 31.3493 20.3375 31.3492 20.3376L27.2258 24.3564L27.0376 24.5399L27.082 24.799L28.0556 30.4748C28.0556 30.4748 28.0556 30.4748 28.0556 30.4748C28.0679 30.5466 28.0644 30.6202 28.0453 30.6904C28.0262 30.7607 27.992 30.826 27.945 30.8816C27.8981 30.9373 27.8395 30.982 27.7735 31.0127C27.7075 31.0434 27.6355 31.0593 27.5627 31.0593Z"
      stroke="url(#paint0_linear_512_38129)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_512_38129"
        x1="18.5715"
        y1="18.2079"
        x2="26.6779"
        y2="28.1249"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3D73EB" />
        <stop offset="1" stopColor="#DE8FFF" />
      </linearGradient>
    </defs>
  </svg>
)
