interface Environment {
  API_URL: string
}

console.log('config', process.env)

const config: Environment = {
  API_URL: process.env.REACT_APP_API_URL ?? 'http://localhost:8443',
}

export default config
