import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { MainButton, MainButtonParams } from '../models/Telegram'
import { mainButton } from '../App'
import { useSafeContext } from './hooks/useSafeContext'
import { FormButtonContext } from './FormButtonContext'

type MainButtonContextValue = MainButton

export const MainButtonContext = createContext<(MainButtonContextValue & { mainButtonState: MainButton }) | null>(null)

export const useDisabledMainButton = (isDisabled: boolean) => {
  const { disable, enable } = useSafeContext(MainButtonContext)
  const { isFormOpen } = useSafeContext(FormButtonContext)

  useEffect(() => {
    // if form is open - ignore since we only want to disable main button
    if (isDisabled && !isFormOpen) {
      disable()
    } else {
      enable()
    }
  }, [isDisabled, isFormOpen, disable, enable])
}

export const MainButtonContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [mainButtonState, setMainButtonState] = useState<MainButton>({ ...mainButton })

  const show = useCallback(() => {
    setMainButtonState(
      prev =>
        prev && {
          ...prev,
          isVisible: true,
        },
    )
    mainButton.show()
  }, [setMainButtonState])

  const hide = useCallback(() => {
    setMainButtonState(
      prev =>
        prev && {
          ...prev,
          isVisible: false,
        },
    )
    mainButton.hide()
  }, [setMainButtonState])

  const enable = useCallback(() => {
    setMainButtonState(
      prev =>
        prev && {
          ...prev,
          isActive: true,
        },
    )
    mainButton.enable()
  }, [setMainButtonState])

  const disable = useCallback(() => {
    setMainButtonState(
      prev =>
        prev && {
          ...prev,
          isActive: false,
        },
    )
    mainButton.disable()
  }, [setMainButtonState])

  const showProgress = useCallback(
    (leaveActive: boolean) => {
      setMainButtonState(
        prev =>
          prev && {
            ...prev,
            isProgressVisible: true,
            isActive: leaveActive,
          },
      )
      mainButton.showProgress(leaveActive)
    },
    [setMainButtonState],
  )

  const hideProgress = useCallback(() => {
    setMainButtonState(
      prev =>
        prev && {
          ...prev,
          isProgressVisible: false,
        },
    )
    // TODO check if hideProgress also set isActive to true
    mainButton.hideProgress()
  }, [setMainButtonState])

  const setParams = useCallback((params: Partial<MainButtonParams>) => {
    setMainButtonState(
      prev =>
        prev && {
          ...prev,
          ...{
            ...params,
            isActive: params.is_active ?? prev.isActive,
            textColor: params.text_color ?? prev.textColor,
            isVisible: params.is_visible ?? prev.isVisible,
          },
        },
    )
    mainButton.setParams(params)
  }, [])

  const value = useMemo(
    () => ({
      mainButtonState,
      ...mainButton,
      show,
      hide,
      enable,
      disable,
      showProgress,
      hideProgress,
      setParams,
    }),
    [mainButtonState, show, hide, enable, disable, showProgress, hideProgress, setParams],
  )

  return <MainButtonContext.Provider value={value}>{children}</MainButtonContext.Provider>
}

/*
setText: (text: string) => void
  onClick: (callback: () => void) => void
  offClick: (callback: () => void) => void
  show: () => void
  hide: () => void
  enable: () => void
  disable: () => void
  showProgress: (leaveActive: boolean) => void
  hideProgress: () => void
  setParams: (params: Partial<MainButtonParams>) => void
  */
