import { Button } from '@mui/joy'
import styles from './PremiumIntro.module.sass'
import { useAppState } from '../AppState'

export const PremiumIntro = () => {
  const { closePremiumIntro } = useAppState()
  return (
    <div className={styles.wrapper}>
      <h2>Эксклюзивные функции с подпиской «Премиум» станут доступны очень скоро</h2>
      <div className={styles.buttonsWrapper}>
        <Button variant="plain" size="lg" color="neutral" sx={{ color: '#fff', flex: 1 }} onClick={closePremiumIntro}>
          Закрыть
        </Button>
      </div>
    </div>
  )
}
