import { birthYearToAge, plural } from '../../tools/StringUtil'
import { Avatar } from '../Avatar/Avatar'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { Caption } from '../Caption/Caption'
import {
  ProfileFinancialPeriod,
  ProfileVariant,
  ProfileWorkType,
  profileFinancialPeriodLabelsCompact,
} from '../../models/Profile'
import { Chip } from '@mui/joy'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import styles from './UserDetails.module.sass'

interface UserDetailsProps {
  personName: string
  birthYear: number
  title?: string
  location?: string
  match?: number
  financialMin?: number
  financialMax?: number
  profilePicture?: string
  workType?: ProfileWorkType[]
  financialPeriod?: ProfileFinancialPeriod
  tags?: string[]
  hasLike?: boolean
  experience?: number
  variant: ProfileVariant
}
export const UserDetails = ({
  personName,
  birthYear,
  profilePicture,
  title,
  match,
  location,
  workType,
  financialMin,
  financialMax,
  financialPeriod,
  tags,
  hasLike,
  experience,
  variant,
}: UserDetailsProps) => {
  const hasFinancialPeriod = financialPeriod && (financialMin || financialMax)

  return (
    <div className={styles.userDetailsWrapper}>
      <div className={styles.detailsHeader}>
        <div>
          <Avatar profilePicture={profilePicture} progress={25} hasLike={hasLike} />
        </div>
        <div className={styles.profileInfo}>
          <Caption className={styles.profileTitle}>{title}</Caption>
          <Caption className={styles.profileBottomLineWrapper}>
            <span className={styles.personName}>
              {personName}, {birthYearToAge(birthYear)} {plural(birthYearToAge(birthYear) ?? 0, ['год', 'года', 'лет'])}
            </span>
            {location && (
              <span className={styles.location}>
                <LocationOnOutlinedIcon className={styles.locationIcon} />
                {location}
              </span>
            )}
          </Caption>
        </div>
      </div>
      {(hasFinancialPeriod || match) && (
        <div className={styles.subheader}>
          {match && (
            <div className={styles.similarity + (hasFinancialPeriod ? '' : ' ' + styles.fullWidth)}>
              <div className={styles.inner}>
                <span className={styles.similarityLevel}>{match}%</span>
                <span className={styles.bottomLabel}>Совпадение</span>
              </div>
            </div>
          )}
          {hasFinancialPeriod && (
            <div className={styles.financialInfo}>
              <div className={styles.inner}>
                <div className={styles.price}>
                  {financialMin && (
                    <>
                      от <span className={styles.primary}>{financialMin} руб</span>
                    </>
                  )}{' '}
                  {financialMax && (
                    <>
                      до <span className={styles.primary}>{financialMax} руб</span>
                    </>
                  )}
                </div>
                <span className={styles.bottomLabel}>{profileFinancialPeriodLabelsCompact[financialPeriod]}</span>
              </div>
            </div>
          )}
        </div>
      )}
      {(workType || tags?.length) && (
        <div className={styles.tags}>
          {experience !== undefined && (
            <Chip color="primary" variant="soft" size="sm">
              {variant === ProfileVariant.Job ? 'От ' : experience === 10 ? 'Более ' : ' '}
              {experience} {plural(experience, ['года', 'лет', 'лет'])} опыта
            </Chip>
          )}
          {workType?.includes(ProfileWorkType.FullTime) && (
            <Chip color="primary" variant="soft" size="sm">
              <div className={styles.workType}>
                <CalendarMonthOutlinedIcon style={{ width: 16, height: 16 }} />
                Full-time
              </div>
            </Chip>
          )}
          {workType?.includes(ProfileWorkType.PartTime) && (
            <Chip color="primary" variant="soft" size="sm">
              <div className={styles.workType}>
                <CalendarMonthOutlinedIcon style={{ width: 16, height: 16 }} />
                Part-time
              </div>
            </Chip>
          )}
          {tags?.slice(0, 3).map(t => (
            <Chip variant="soft" color="primary" key={t} size="sm">
              {t.toUpperCase()}
            </Chip>
          ))}
          {tags?.length && tags.length > 3 && (
            <Chip variant="soft" size="sm" color="neutral">
              +{tags.length - 3}
            </Chip>
          )}
        </div>
      )}
    </div>
  )
}
