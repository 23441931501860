export const LikeMiniIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.5112 5.65652C13.3145 5.42036 13.0683 5.23024 12.7901 5.0996C12.5119 4.96897 12.2084 4.90098 11.901 4.90046H8.70876L9.10079 3.89937C9.26386 3.46105 9.31816 2.98971 9.25905 2.5258C9.19994 2.06188 9.02917 1.61922 8.76139 1.2358C8.49362 0.852378 8.13683 0.539636 7.72163 0.3244C7.30643 0.109164 6.84522 -0.00214179 6.37755 3.12166e-05C6.24289 0.000312194 6.11116 0.0394273 5.99817 0.112687C5.88518 0.185947 5.79571 0.290241 5.7405 0.413067L3.74533 4.90046H2.10018C1.54318 4.90046 1.00899 5.12173 0.615129 5.51559C0.221269 5.90945 0 6.44364 0 7.00064V11.9011C0 12.4581 0.221269 12.9923 0.615129 13.3861C1.00899 13.78 1.54318 14.0012 2.10018 14.0012H11.012C11.5033 14.0011 11.979 13.8287 12.3563 13.514C12.7336 13.1994 12.9887 12.7624 13.0771 12.2791L13.9662 7.37867C14.0212 7.07581 14.0089 6.76457 13.9303 6.46697C13.8517 6.16937 13.7086 5.89269 13.5112 5.65652ZM3.5003 12.6011H2.10018C1.91452 12.6011 1.73645 12.5274 1.60517 12.3961C1.47388 12.2648 1.40012 12.0867 1.40012 11.9011V7.00064C1.40012 6.81497 1.47388 6.63691 1.60517 6.50562C1.73645 6.37434 1.91452 6.30058 2.10018 6.30058H3.5003V12.6011ZM12.6011 7.12665L11.712 12.0271C11.6822 12.1902 11.5955 12.3374 11.4673 12.4425C11.3391 12.5476 11.1777 12.6038 11.012 12.6011H4.90043V5.74753L6.80459 1.46316C7.0006 1.5203 7.18263 1.61746 7.3392 1.74849C7.49577 1.87953 7.62347 2.0416 7.71425 2.22447C7.80503 2.40734 7.85691 2.60705 7.86662 2.81099C7.87634 3.01492 7.84367 3.21865 7.77068 3.40933L7.39964 4.41042C7.32058 4.62201 7.29389 4.8496 7.32185 5.07374C7.34981 5.29789 7.43158 5.51195 7.56019 5.69764C7.6888 5.88334 7.86043 6.03516 8.06044 6.14015C8.26044 6.24514 8.48287 6.30018 8.70876 6.30058H11.901C12.0039 6.30041 12.1055 6.32291 12.1987 6.36647C12.2918 6.41003 12.3743 6.47358 12.4401 6.5526C12.5075 6.63053 12.5569 6.7224 12.5848 6.82163C12.6126 6.92086 12.6182 7.02501 12.6011 7.12665Z"
      fill="url(#paint0_linear_499_34847)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_499_34847"
        x1="4.6"
        y1="4.20037"
        x2="10.6006"
        y2="11.2005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3D73EB" />
        <stop offset="1" stopColor="#DE8FFF" />
      </linearGradient>
    </defs>
  </svg>
)
