import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import styles from './TopBackLink.module.sass'
import { useNavigate } from 'react-router-dom'

interface TopBackLinkProps {
  to?: string
  onClick?: () => void
}
export const TopBackLink = ({ to, onClick }: TopBackLinkProps) => {
  const navigate = useNavigate()

  return (
    <span
      className={styles.backButton}
      onClick={() => {
        if (onClick) {
          return onClick()
        }

        if (to) {
          navigate(to)
        } else {
          navigate(-1)
        }
      }}
    >
      <ChevronLeftIcon /> Назад
    </span>
  )
}
