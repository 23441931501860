import { DetailedHTMLProps, HTMLAttributes } from 'react'
import styles from './Caption.module.sass'

export const Caption = (props: DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>) => {
  return (
    <p {...props} className={styles.root + (props.className ? ` ${props.className}` : ``)}>
      {props.children}
    </p>
  )
}
