import { Outlet } from 'react-router-dom'
import { PageLayout } from '../../ui/PageLayout/PageLayout'
import { TopBackLink } from '../../ui/TopBackLink/TopBackLink'

export const SettingsPage = () => {
  return (
    <PageLayout title="Настройки" topMenu>
      <TopBackLink />
      <Outlet />
    </PageLayout>
  )
}
